import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "lemajic_transfers_to_gent" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>🔥🔥🔥 The central striker of RFS (Riga, 🇱🇻), Darko Lemajic, the player from Serbia 🇷🇸, yesterday officially transferred to Belgium JPL club KAA Gent 🇧🇪. Darko’s transfer to KAA Gent has become one of the biggest in Latvian football in recent years.</p>

          <p>We would like to remind that Darko moved to Riga FC from FK Indija in July 2017. In the 2017/2018 season, he was the top scorer in the Latvian Championship with Riga FC, and on seasons 2018-2019 he moved to RFS Football Club and in the same season, he again was the top scorer in the Latvian Championship. </p>

          <p>All transfers for Darko Lemajic in Latvia were organized and provided by the Catenaccio Sports Agency. We wish him the best of luck and great achievements in his new club.
.</p>
        </div>
      </div>
    )
  }
}